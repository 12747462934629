<template>
  <div>
    <el-dialog :title="text+'规则'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               :validate-on-rule-change="false"
               ref="formRefs"
               label-width="120px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="类型:">
              <el-radio-group v-model="form.auth_type">
                <el-radio :label="2">接口</el-radio>
                <el-radio :label="1">菜单(菜单只显示三级)</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="按钮名称:">
              <el-input clearable
                        placeholder="请输入按钮名称"
                        v-model="form.menu_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="父级分类:">
              <!-- <el-input clearable  clearable  placeholder="请选择父级分类"
                        v-model="form.pid"
                        style="width:100%" /> -->
              <el-cascader v-model="form.pid"
                           style="width:100%"
                           :options="$parent.tableData"
                           :props="props"
                           :show-all-levels="false"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10"
                v-if="form.auth_type == 1">
          <el-col :span="12">
            <el-form-item label="接口参数:">
              <el-input clearable
                        placeholder="举例a/123456"
                        v-model="form.params"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路由名称:"
                          prop="menu_path">
              <el-input clearable
                        placeholder="请输入路由名称"
                        v-model="form.menu_path"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10"
                v-else>
          <el-col :span="12">
            <el-form-item label="请求方式:">
              <el-input clearable
                        placeholder="举例a/123456"
                        v-model="form.methods"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="接口地址:"
                          prop="api_url">
              <el-input clearable
                        placeholder="举例a/123456"
                        v-model="form.api_url"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="权限标识:"
                          prop="unique_auth">
              <el-input clearable
                        placeholder="请输入权限标识"
                        v-model="form.unique_auth"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="图标:">
              <el-input clearable
                        placeholder="请选择图标"
                        v-model="form.icon"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="12"
                  v-if="form.auth_type == 1">
            <el-form-item label="排序:">
              <el-input clearable
                        placeholder="请输入排序"
                        v-model="form.sort"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态">
              <el-radio-group v-model="form.is_show">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="隐藏菜单">
              <el-radio-group v-model="form.is_header">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      text: '新增',
      radio: '3',
      form: {},
      rules: {},
      props: {
        emitPath: false,
        label: 'menu_name',
        value: 'id',
        children: 'child',
        checkStrictly: true
      }
    }
  },
  watch: {
    'form.auth_type' () {
      if (this.form.auth_type == 2) {
        var rules = {
          api_url: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
        }
      } else if (this.form.auth_type == 1) {
        // this.form.params = ''
        // this.form.menu_path = ''
        // this.form.sort = ''
        var rules = {
          menu_path: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
          unique_auth: [
            { required: true, message: '请输入', trigger: 'blur' },
          ]
        }
      }
      this.$nextTick(() => {
        this.rules = rules
      })
    }
  },
  methods: {
    handleClose () {
      this.text = '新增'
      this.$refs.formRefs.resetFields()
      this.dialogVisible = false
    },
    handleSave () {
      this.$refs.formRefs.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v2/tc_power/create',
          method: 'POST',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          // this.$parent.initData()
          this.$http({
            url: '/api/v1/index/clear',
            method: 'get'
          })
          this.handleClose()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>