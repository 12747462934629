<template>
  <div>
    <el-form label-width="100px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="规则状态:">
            <el-select style="width:100%"
                       v-model="params.is_show"
                       @change="search"
                       placeholder="请选择">
              <el-option v-for="(item,index) in statusList"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="按钮名称:">
            <el-input clearable
                      placeholder="请输入"
                      v-model="params.menu_name"
                      style="width:100%"
                      @keyup.enter.native="search" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="addPermission">添加规则</el-button>
        </el-col>
      </el-row>
    </el-form>

    <TreeTable :stripe="true"
               :border="true"
               :data="tableData"
               v-if="tableData.length"
               :tree-type="true"
               :columns="tableColumns"
               children-prop="child"
               :expand-type="false"
               :show-row-hover="true"
               :selection-type="false">

      <template slot="is_show"
                slot-scope="{row}">
        <div :class="getClass('is_show',row.is_show)">
          {{getValue('is_show',row.is_show)}}
        </div>
      </template>

      <template slot="opt"
                slot-scope="{row}">
        <div class="text_btn_primary"
             @click="addChild(row)">
          添加子菜单
        </div>
        <div class="text_btn_primary"
             @click="editRow(row)">
          编辑
        </div>
        <div class="text_btn_danger"
             @click="delRow(row)">
          删除
        </div>
      </template>
    </TreeTable>
    <Permission ref="permissionRef" />
  </div>
</template>

<script>
import Permission from './components/form.vue'
export default {
  data () {
    return {
      statusList: [
        { value: 0, label: '不显示' },
        { value: 1, label: '显示' },
        { value: '', label: '全部' }
      ],
      tableData: [],
      params: {
        is_show: '',
        menu_name: ''
      },
      form: {
        auth_type: 1,
        menu_name: '',
        pid: '',
        params: '',
        menu_path: '',
        unique_auth: '',
        icon: '',
        sort: 0,
        is_show: 1,
        is_header: 0,
        methods: '',
        api_url: '',
      },
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'settingPermissionAdmin',
      tableColumns: [
        // { prop: 'id', align: 'left', label: 'ID', width: '100' },
        { prop: 'menu_name', align: 'left', label: '按钮名称', width: '150' },
        { prop: 'api_url', align: 'center', label: '接口路径', width: '' },
        { prop: 'unique_auth', align: 'center', label: '前端权限', width: '' },
        { prop: 'menu_path', align: 'center', label: '页面路由', width: '' },
        { prop: 'is_show', align: 'center', label: '规则状态', width: '100', template: 'is_show', type: 'template', },
        {
          prop: 'aaa', align: 'center', label: '操作', width: '200', type: 'template', template: 'opt',
          btnType: 'text', btns: [
            { name: '添加子菜单', class: 'text_btn_primary', event: 'addChild' },
            { name: '编辑', class: 'text_btn_primary', event: 'editRow' },
            { name: '删除', class: 'text_btn_danger', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Permission
  },
  mounted () {
    this.search()
    // this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getClass (prop, value) {
      if (prop == 'is_show') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'is_show') {
        return value ? '开启' : '关闭'
      }
    },
    initData () {
      this.$http({
        url: '/api/v2/tc_power/lst',
        method: 'get',
        params: this.params
      }).then(res => {
        this.tableData = res.data.list
      })
    },
    addPermission () {
      this.$refs.permissionRef.form = JSON.parse(JSON.stringify(this.form))
      this.$refs.permissionRef.dialogVisible = true
    },
    addChild (row) {
      let form = {
        auth_type: 1,
        menu_name: '',
        pid: '',
        params: '',
        menu_path: '',
        unique_auth: '',
        icon: '',
        sort: 0,
        is_show: 1,
        is_header: 1,
        methods: '',
        api_url: '',
      }
      form.pid = row.id
      this.$refs.permissionRef.form = form
      this.$refs.permissionRef.dialogVisible = true
    },
    editRow (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.permissionRef.form = {
        auth_type: form.auth_type,
        menu_name: form.menu_name,
        pid: form.pid,
        params: form.params,
        menu_path: form.menu_path,
        unique_auth: form.unique_auth,
        icon: form.icon,
        sort: form.sort,
        is_show: form.is_show,
        is_header: form.is_header,
        methods: form.methods,
        api_url: form.api_url,
        id: form.id
      }
      this.$refs.permissionRef.text = '修改'
      this.$refs.permissionRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/tc_power/del',
          method: 'post',
          data: {
            id: row.id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },
    search () {
      this.initData()
    },
    refresh () {

    },
    statusChange (row) {
      this.$http({
        url: '/api/v2/tc_power/menus_open',
        method: 'post',
        data: {
          id: row.id,
          is_show: row.is_show - 0
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.search()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>